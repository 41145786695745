<template>
  <div class="backgroundDiv">
 

    <input type="text" v-model="value">
     <button @click="btn">发送</button>
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      value:""
    };
  },
   methods:{
     btn(){
      //  console.log(this.value);
       this.$axios({
         url:"https://api.mhimg.cn/api/gpt_lianaiqiluo/?prompt=",
         params:{
           prompt:this.value
         }
       }).then(result => {
         console.log(result);
       })
     }
   }
 
};
</script>

<style scoped>
 
 *{
   padding: 0;
   margin: 0;
 }

.backgroundDiv {
  margin: 0 auto;
  width: 700px;
  height: 550px;
  background: rgb(217, 237, 249);
  border-radius: 2%;
   margin-top: 50px;
}


</style>