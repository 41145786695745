import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/view/home.vue';  // 引入主页组件
import Bestow from '@/view/bestow.vue';
import Navigation from '@/view/navigation.vue';
import Robot from '@/view/robot.vue';
import Tool from '@/view/tool.vue';

Vue.use(VueRouter);

const routes = [
  { path: '/home', component: Home },
  { path: '/', redirect: '/home' },
  { path: '/bestow', component: Bestow },
  { path: '/navigation', component: Navigation },
  { path: '/robot', component: Robot },
  { path: '/tool', component: Tool }
];

const router = new VueRouter({
  routes
});

export default router;