<template>
  <div class="container">
    <ul class="list">
      <li @click="selectComponent('cpt1')" class="list-item" :class="{ 'active animate__animated animate__bounceIn': selectedComponent === 'cpt1' }" >年龄查询</li>
      <li @click="selectComponent('cpt2')" class="list-item" :class="{ 'active animate__animated animate__bounceIn': selectedComponent === 'cpt2' }" >测试</li>
      <li @click="selectComponent('cpt3')" class="list-item" :class="{ 'active animate__animated animate__bounceIn': selectedComponent === 'cpt3' }" >写点什么吧...</li>
      <li @click="selectComponent('cpt4')" class="list-item" :class="{ 'active animate__animated animate__bounceIn': selectedComponent === 'cpt4' }" >写点什么吧...</li>
    </ul>
        
    <component :is="selectedComponent"></component>
  </div>
</template>

<script>
import cpt1 from '@/components/module/cpt1.vue'
import cpt2 from '@/components/module/cpt2.vue'
import cpt3 from '@/components/module/cpt3.vue'
import cpt4 from '@/components/module/cpt4.vue'
export default {
  data () {
    return {
      selectedComponent: null
    }
  },
  components: {
    cpt1,
    cpt2,
    cpt3,
    cpt4
  },
  methods: {
    selectComponent(component) {
      this.selectedComponent = component;
    }
  }
}
</script>

<style scoped>
.container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 7%;
}

.list {
  display: flex;
  justify-content: center;
  padding: 0;
  list-style: none;
}

.list-item {
  margin-right: 20px;
  width: 200px;
  height: 50px;
  background-color: rgb(240, 240, 240);
  border-radius: 15px;
  font-size: 20px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  transition: background-color 3s, color 3s;
}

.list-item.active {
  background-color: rgb(51, 51, 51);
  color: #ccc;
}
</style>
