<template>
    <div class="father">
      <div class="head">
        <div class="headLeft">沙币机器人</div>
        <div class="headRight"><img src="../image/PQ.png" alt="" /></div>
      </div>
  
      <div class="neirong" ref="messageContainer">
        <div class="message" v-for="(msg, index) in messages" :key="index">
          <div
            class="avatar"
            :class="{
              'avatar-left': msg.sender === 'robot',
              'avatar-right': msg.sender === 'user',
            }"
          >
            <img :src="getAvatar(msg.sender)" alt="" />
          </div>
          <div
            class="text"
            :class="{
              'text-left': msg.sender === 'robot',
              'text-right': msg.sender === 'user',
            }"
          >
            {{ msg.text }}
          </div>
        </div>
      </div>
  
      <div class="bottom">
        <div class="bottomLeft"><img src="../image/mrtx.jpg" alt="" /></div>
        <div class="bottomzj">
          <el-input
            placeholder="说点什么吧..."
            @keydown.enter.native="GetSend"
            v-model="input"
            clearable
            class="ipt"
          ></el-input>
        </div>
        <div class="bottomRight">
          <el-button type="primary" class="btn" @click="GetSend">发送</el-button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        input: "",
        messages: [],
      };
    },
    methods: {
      GetSend() {
        if (this.input.trim() === "") {
          return;
        }
  
        const userInput = this.input; // 先保存用户输入的消息
  
        this.messages.push({ text: userInput, sender: "user" });
  
        this.$axios({
          url: "https://api.mhimg.cn/api/gpt_lianaiqiluo/?prompt=",
          params: {
            prompt: userInput,
          },
        })
          .then((result) => {

            const replyText = result.data;
  
            this.messages.push({ text: replyText, sender: "robot" });
  
            this.$nextTick(() => {
              const container = this.$refs.messageContainer;
              container.scrollTop = container.scrollHeight;
            });
          })
          .catch((error) => {
            console.error(error);
          });
  
        this.input = ""; // 发送完消息后清空输入框
  
        this.$nextTick(() => {
          const container = this.$refs.messageContainer;
          container.scrollTop = container.scrollHeight;
        });
      },
  
      getAvatar(sender) {
        if (sender === "user") {
          return require("@/image/mrtx.jpg"); // 用户头像路径
        } else if (sender === "robot") {
          return require("@/image/PQ.png"); // 机器人头像路径
        }
      },
    },
  };
  </script>
  
  <style>
  .father {
    position: relative;
    margin: 0 auto;
    width: 400px;
    height: 595px;
    background-color: rgb(244, 234, 243);
    border-radius: 10px;
    top: 50px;
  }
  
  .head {
    position: absolute;
    top: 0;
    width: 100%;
    height: 60px;
    background: linear-gradient(
      90deg,
      rgba(246, 60, 47, 0.6),
      rgba(128, 58, 242, 0.6)
    );
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-bottom: 60px;
  }
  .headLeft {
    /* float: left; */
    line-height: 60px;
    font-size: 20px;
    color: azure;
    margin-left: 30px;
    display: inline-block;
  }
  .headRight {
    float: right;
    margin-right: 20px;
    margin-top: 10px;
  }
  .headRight img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 55px;
    background-color: rgb(255, 255, 255);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    display: inline-block;
    
  }
  .bottomLeft {
    margin-top: 10px;
    margin-left: 10px;
  }
  .bottomLeft img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .bottomzj {
    /* line-height: 55px; */
    width: 250px;
    height: 40px;
    /* background-color: pink; */
    margin-top: -46px;
    margin-left: 65px;
    /* line-height: 55px; */
  }
  .bottomRight {
    float: right;
    margin-top: -39px;
    margin-right: 6px;
  }
  
  .neirong {
    position: absolute;
    width: 100%;
    height: 525px;
    top: 60px;
  }
  
  .user-message {
    background-color: #ccefff;
    text-align: right;
  }
  
  .robot-message {
    background-color: #e6e6e6;
    text-align: left;
  }
  
  .message {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
  }
  
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
  }
  
  .avatar-left {
    margin-right: 15px;
  }
  
  .avatar-right {
    margin-right: 15px;
  }
  
  .text {
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    max-width: 70%;
  }
  
  .text-left {
    background-color: rgb(255, 255, 255);
    color: black;
  }
  
  .text-right {
    background-color: rgb(149, 236, 105);
    color: black;
  }
  
  .neirong {
    position: absolute;
    width: 100%;
    height: 480px;
    top: 60px;
    overflow-y: auto; /* 设置为自动，只在内容溢出时显示滚动条 */
    scroll-behavior: smooth; /* 平滑滚动效果 */
    background-color: rgb(245, 245, 245);
  }
  
  @media screen and (max-width: 500px) {
    .father {
      width: 350px !important;
    }
  
    .ipt{
      width: 200px;
    }

  
  }
 .text-right{
   color: red;
 }
  </style>