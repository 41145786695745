<template>
    <div>
        <div class="tp">
            <img src="../image/wx.jpg" alt="" width="400px" height="500px">
            <img src="../image/zfb.jpg" alt="" width="400px" height="500px">
  
        </div>
  
        <div class="phoneimg">
              <img src="@/image/wx.jpg" alt="" width="300px" height="340px">
              <img src="@/image/zfb.jpg" alt="" width="300px" height="340px">
        </div>
    </div>
  </template>
  
  <script>
  export default {
  data () {
      return {
  
      }
  },
  
  }
  </script>
  
  <style>
   .tp{
       position: absolute;
       top: 50%;
       margin-top:-250px;
       left: 50%;
       margin-left: -450px;
       width: 900px;
       height: 500px;
   }
  
  .phoneimg{
      margin: 0 auto;
    width:300px;
    height: 600px;
    /* background-color: pink; */
  
  }
  
  
  @media (max-width: 480px) {
   .tp{
       display: none;
   }
  }
  
  @media (min-width: 480px) {
   .phoneimg{
       display: none;
   }
  }
  
  
  </style>