import Vue from "vue";
import App from "./App.vue";

import router from "@/router/index.js"; // 引入路由配置文件
import ElementUI from "element-ui"; //element -ui组件库
import "element-ui/lib/theme-chalk/index.css"; //element -ui样式
import axios from "@/http/axios"; //axios
import "animate.css"; //animate 动画库

Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
