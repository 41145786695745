<template>
  <div>

    <div class="header" >
        <div class="headerContent ">

          <div class="animate__animated animate__slideInLeft"  >  <router-link to="home">首页</router-link> </div>
          <div class="animate__animated animate__slideInDown">  <router-link to="robot" >聊天机器人</router-link>  </div>
          <div class="animate__animated animate__slideInUp">   <router-link to="bestow">捐赠作者</router-link> </div>
          <div class="animate__animated animate__slideInDown"> <router-link to="tool">功能页面</router-link>  </div>
          <div class="animate__animated animate__slideInRight">  <router-link to="navigation">网站导航</router-link>  </div>
          
         
         
          
         
          
        </div>
        <!-- <div class="zhuanquan"></div> -->
    </div>

        <div class="layer1"></div>
    <div class="layer2"></div>
    <div class="layer3"></div>
    <router-view ></router-view>
   
     <div class="headerBottom">
       <p class="animate__animated animate__slideInUp">gaoxin.xyz Copyright©2024-2024. All Rights Reserved</p>
       <a  class="animate__animated animate__slideInDown" href="https://beian.miit.gov.cn/"  target="_blank">冀ICP备2024055604号-1</a>
     </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.btn();
  },
   created(){
      this.title()
   },
  methods: {

    // 网站title
    title(){
      document.title = "Leon"
    },


    //导航排它效果
    btn() {
      let a = document.querySelectorAll(".headerContent div");
      for (let i = 0; i < a.length; i++) {
        a[i].addEventListener("click", (e) => {
          let is = e.currentTarget;
          // 移除其他导航元素上的类名
          for (let j = 0; j < a.length; j++) {
            if (a[j] !== is) {
              a[j].classList.remove("dianshuishuibian");
            }
          }
          // 给当前被点击的导航元素添加类名
          is.classList.add("dianshuishuibian");
        });
      }
    },
  },
};
</script>

<style lang="scss">


/* 星空背景 */
@import '@/components/background.scss';
html{
  height: 100%;
  background: radial-gradient(ellipse at bottom,#1b2735 0%,#090a0f 100%);
  overflow: hidden;
}
.title{
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  color: #fff;
  text-align: center;
  font-family: 'lato',sans-serif;
  font-weight: 300px;
  font-size: 50px;
  letter-spacing: 10px;
  margin-top: -60px;
  padding-left: 10px;
  background-clip: text;
  background: linear-gradient(white,#38495a);
  -webkit-background-clip: text;
  color: transparent;
}
/* 星空背景 */







 body{
  //  background-color: #1b2735;
 }


.header {
  float: right;
  position: relative;
  margin: 0 auto;
  /* margin-top: 20px; */
  width: 500px;
  height: 40px;
  background-color: transparent;
  text-align: center;
  overflow: hidden;
}

.headerContent {
  position: absolute;
  top: 50%; /* 垂直居中 */
  left: 50%; /* 水平居中 */
  margin-top: -30px;
  margin-left: -245px;
  
  width: 490px;
  height: 60px;
  background: linear-gradient(
      90deg,
      rgba(246, 60, 47, 0.6),
      rgba(128, 58, 242, 0.6)
    );
  font-size: 20px;
  z-index: 1;

}

.zhuanquan{
  width: 250px;
  height: 30px;
  background-color: red;
  position: absolute;
  left: 50%;
  top: 50%;
  transform-origin: top left;
  animation: rotate 5s linear infinite; /* 应用旋转动画，持续时间为2秒，线性变化，无限循环 */
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.headerContent{
  line-height: 60px;
}
.headerContent a{
  margin-right: 10px;
  text-decoration: none;
  margin-left: 5px;
  color: #ccc;
  transition: text-decoration 6s; 
}

.headerContent div{
  display: inline-block;
}

.headerBottom{
  position: absolute;
  width: 100%;
  color: white;
  text-align: center;
  bottom: 0;
}
.headerBottom p{
  display: inline-block;
  margin-right: 30px;
}
.headerBottom a{
  text-decoration: none;
  color: white;
}

.headerContent div a:hover{
  color: black;
}

.dianshuishuibian{
  color: black;
}




.headerContent a{
  margin-right: 10px;
  text-decoration: none;
  margin-left: 5px;
  color: #ccc;
  transition: color 0.6s; /* 修改颜色的过渡效果 */
}

.headerContent div.dianshuishuibian a {
  color: black; /* 设置被选中的导航文字颜色为黑色 */
}



//手机端 媒体查询设置
@media only screen and (max-width: 600px) {
  .headerContent div:nth-child(4) {
    display: none; // 在手机端隐藏第四个导航元素（功能页面）
  }
 .headerBottom{
   display: none;
 }
.header{
  width: 355px;
}
 .headerContent div a{
   
   font-size: 20px;
 
 }
}
</style>