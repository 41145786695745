<template>
  <div class="Mainbody">
     <div class="concent">
       <ul class="list-container">
         <li> <a href="https://cn.vuejs.org/" class="ulLink" target="_blank"><img src="@/image/icon/Vue.png" alt="" width="80px"> <p>Vue</p></a></li>
         <li> <a href="https://router.vuejs.org/zh/" class="ulLink"  target="_blank"><img src="@/image/icon/Vue.png" alt="" width="80px"> <p>Vue-Router</p></a></li>
         <li> <a href="https://vuex.vuejs.org/zh/" class="ulLink"  target="_blank"><img src="@/image/icon/Vue.png" alt="" width="80px"> <p>VueX</p></a></li>
         <li>  <a href=" https://angular.cn/" class="ulLink"  target="_blank"><img src="@/image/icon/Angular.png" alt="" width="80px"><p>Angular</p></a></li>
         <li>  <a href=" https://jquery.com/" class="ulLink"  target="_blank"><img src="@/image/icon/jquery.png" alt="" width="80px"><p>JQuery</p></a></li>
         <li><a href="https://react.dev/" class="ulLink" target="_blank"> <img src="@/image/icon/React.png" alt="" width="80px"> <p>React</p></a></li>
         <li>  <a href="https://element.eleme.cn/#/zh-CN" class="ulLink" target="_blank"><img src="@/image/icon/elementui.png" alt="" width="80px"><p>Element-ui</p></a></li>
         <li>  <a href="https://ant.design/index-cn" class="ulLink" target="_blank"><img src="@/image/icon/antdesign.png" alt="" width="80px"><p>AntDesign</p></a></li>
         <li>  <a href="https://www.bootcss.com/" class="ulLink" target="_blank"><img src="@/image/icon/Bootstrap.png" alt="" width="80px"><p>Bootstrap</p></a></li>
         <li>  <a href="http://mui.ucmed.cn/#/zh-CN/intro" class="ulLink" target="_blank"><img src="@/image/icon/vant.png" alt="" width="80px"><p>Vant</p></a></li>
         <li> <a href="https://uniapp.dcloud.net.cn/" class="ulLink" target="_blank"><img src="@/image/icon/uni-app.png" alt="" width="80px"> <p>uni-app</p></a></li>
         <li><a href="https://www.axios-http.cn/" class="ulLink" target="_blank"> <img src="@/image/icon/axios.png" alt="" width="80px"> <p>axios</p></a></li>
         <li> <a href="https://nodejs.org/en" class="ulLink" target="_blank"><img src="@/image/icon/Node.js.png" alt="" width="80px"> <p>Node.js</p></a></li>
         <li> <a href="https://gitee.com/" class="ulLink" target="_blank"> <img src="@/image/icon/gitee.png" alt="" width="80px"><p>Gitee</p></a></li>
         <li> <a href="https://github.com/" class="ulLink" target="_blank"> <img src="@/image/icon/github.png" alt="" width="80px"><p>GitHub</p></a></li>
         <li>  <a href="https://www.csdn.net/" class="ulLink" target="_blank"><img src="@/image/icon/csdn.png" alt="" width="80px"><p>CSDN</p></a></li>
         <li> <a href="https://www.runoob.com/" class="ulLink" target="_blank"> <img src="@/image/icon/cainiao.png" alt="" width="80px"><p>菜鸟教程</p></a></li>
         <li> <a href="https://mp.weixin.qq.com/" class="ulLink" target="_blank"> <img src="@/image/icon/weixin.png" alt="" width="80px"><p>微信开放平台</p></a></li>
         <li> <a href="https://www.lodashjs.com/" class="ulLink" target="_blank"> <img src="@/image/icon/lodash.png" alt="" width="80px"><p>Lodash</p></a></li>
         <li> <a href="https://vuetifyjs.com/en/" class="ulLink" target="_blank"> <img src="@/image/icon/vuetify.png" alt="" width="80px"><p>Vuetify</p></a></li>

       </ul>
     </div>
  </div>
</template>

<script>
export default {

}
</script>


<style>
.Mainbody{
  position: relative;
  width: 800px;
  height: 800px;
  left: 50%;
  margin-left: -400px;
  padding-top: 50px;
}
.concent{
  width: 800px;
  height: 600px;
  /* background-color: skyblue; */
}
.concent ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
}

.concent ul li{
  display: inline-block;
  width: 140px;
  height: 140px;
  list-style: none;
  margin-bottom: 10px; /* 添加间距 */
  /* background-color: pink; */
  text-align: center;
}

.container li image{
  display: inline-block;
}

.ulLink{
   text-decoration: none;
   /* font-size: 10px; */
   color: white;
}

@media only screen and (max-width: 600px) {
  .Mainbody {
    width: 100%;
    height: 100vh; /* 使用视口高度作为高度 */
    left: 0;
    margin-left: 0;
    padding-top: 20px;
    overflow: auto; /* 添加滚动条 */
  }

  .concent {
    width: 100%;
    height: auto; /* 自适应高度 */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-bottom: 20px; /* 为了确保内容底部不被遮挡 */
  }

  .concent ul {
    padding: 0;
  }

  .concent ul li {
    width: 45%;
    height: 140px;
    list-style: none;
    margin-bottom: 10px;
    text-align: center;
  }
}
</style>
