var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"father"},[_vm._m(0),_c('div',{ref:"messageContainer",staticClass:"neirong"},_vm._l((_vm.messages),function(msg,index){return _c('div',{key:index,staticClass:"message"},[_c('div',{staticClass:"avatar",class:{
          'avatar-left': msg.sender === 'robot',
          'avatar-right': msg.sender === 'user',
        }},[_c('img',{attrs:{"src":_vm.getAvatar(msg.sender),"alt":""}})]),_c('div',{staticClass:"text",class:{
          'text-left': msg.sender === 'robot',
          'text-right': msg.sender === 'user',
        }},[_vm._v(" "+_vm._s(msg.text)+" ")])])}),0),_c('div',{staticClass:"bottom"},[_vm._m(1),_c('div',{staticClass:"bottomzj"},[_c('el-input',{staticClass:"ipt",attrs:{"placeholder":"说点什么吧...","clearable":""},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.GetSend.apply(null, arguments)}},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}})],1),_c('div',{staticClass:"bottomRight"},[_c('el-button',{staticClass:"btn",attrs:{"type":"primary"},on:{"click":_vm.GetSend}},[_vm._v("发送")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head"},[_c('div',{staticClass:"headLeft"},[_vm._v("沙币机器人")]),_c('div',{staticClass:"headRight"},[_c('img',{attrs:{"src":require("../image/PQ.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottomLeft"},[_c('img',{attrs:{"src":require("../image/mrtx.jpg"),"alt":""}})])
}]

export { render, staticRenderFns }