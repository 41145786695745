<template>
  <div class="BackgroundCpt3">
    <input type="text" v-model="value">
    <button @click="btn">查询</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: "",
      url: "https://api.toolnb.com/ext/ipareadata.json",
      token: "445b108df38ab32a63b92f8656c44b7f"
    };
  },
  methods: {
    btn() {
      this.$axios.post(this.url, {
        ip: this.value,
        token: this.token
      }).then(result => {
        console.log(result.data.data.ipResult[0].ipData);
      }).catch(error => {
        console.error(error);
      });
    }
  }
};
</script>

<style>
.BackgroundCpt3 {
  margin: 0 auto;
  width: 700px;
  height: 550px;
  background: rgb(217, 237, 249);
  border-radius: 2%;
   margin-top: 50px;
}
</style>
