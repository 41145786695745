<template>
  <div class="MainBody">
    <div class="left" ref="left" contenteditable @input="shibie"></div>
    <div class="right">
      <p v-for="(item, index) in result" :key="index" :style="{ color: item.color }">
        {{ item.name }} {{ item.id }}，{{ '年龄' + item.age + '岁' }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      result: [],
    };
  },
  methods: {
    shibie() {
      let a = this.$refs.left.innerText;
      let lines = a.split("\n");
      let result = [];
      for (let line of lines) {
        let parts = line.split("\t");
        if (parts.length === 2) {
          let name = parts[0].trim();
          let id = parts[1].trim();
          let age = new Date().getFullYear() - parseInt(id.substr(6, 4));
          let color = "";
          if (age < 16 || age > 60) {
            color = "red";
          }
          result.push({ name, id, age, color });
        }
      }
      this.result = result;
    },
  },
};
</script>

<style>
.MainBody {
  margin: 0 auto;
  margin-top: 50px;
  width: 700px;
  height: 545px;
  background-color: #333; /* 更改背景颜色为黑色 */
  border: 5px solid #666; /* 更改大边框颜色为深灰色 */
  border-radius: 10px;
  display: flex;
}

.left {
  flex: 1;
  height: 96%;
  border: 2px solid #ff5b5b; /* 更改小边框颜色为红色 */
  overflow: auto;
  border-radius: 10px;
  font-size: 15px;
  padding: 10px;
  background-color: #444; /* 更改左侧背景颜色为深灰色 */
}

.right {
  flex: 1;
  height: 98%;
  overflow:  auto;
  font-size: 15px;
  padding: 10px;
  border-radius: 1%;
  /* background-color: #555;  */
}
.right p {
  margin: 5px 0;
  color: #eee; /* 更改文字颜色为浅灰色 */
}
</style>
